/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { Box, Flex, useThemeUI } from 'theme-ui';

import { analyticsClient } from '../../analytics';
import { useCustomColorMode } from '../../hooks/useCustomColorMode';
import { rangeWithKey } from '../../utils/helpers';
import { onEnter } from '../../utils/onEnter';
import { Container } from '../Container';
import {
  ButtonBoxStyled,
  InputDotStyled,
  NumberStyled,
  RatingDescriptionStyled,
} from './NpsRating.styles';
import { StepHeadline } from './StepHeadline';

type Props = {
  header: string;
  onChange: (value: number) => void;
  lowLabel: string;
  highLabel: string;
  value: number | null;
  stepCount?: number;
  currentStep?: number;
  surveyId: string;
};

export const NpsRating = ({
  header,
  onChange,
  value,
  lowLabel,
  highLabel,
  currentStep,
  stepCount,
  surveyId,
}: Props) => {
  const { theme } = useThemeUI();

  useEffect(() => {
    analyticsClient.surveyQuestionPageOpened(surveyId, currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCustomColorMode();
  return (
    <>
      <StepHeadline title={header} currentStep={currentStep} stepCount={stepCount} />

      <Container>
        <form>
          <Flex
            sx={{
              maxWidth: '300px',
              margin: '0 auto',
              flexWrap: 'wrap',
              justifyContent: 'center',
              '@media screen and (min-width: 64em)': {
                maxWidth: 'none',
                justifyContent: 'space-between',
              },
            }}
          >
            {rangeWithKey(11).map((key, index) => (
              <ButtonBoxStyled
                isActive={index === value}
                activeColor={String(theme.colors?.secondary)}
                elementIndex={index}
                key={key}
                tabIndex={0}
                onKeyPress={onEnter(() => onChange(index))}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <label htmlFor={`radio-${index}`} onClick={() => onChange(index)}>
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <NumberStyled
                      isActive={index === value}
                      activeColor={String(theme.colors?.primary)}
                    >
                      {index}
                    </NumberStyled>
                    <Box
                      sx={{
                        display: 'none',
                        '@media screen and (min-width: 64em)': {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '48px',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <InputDotStyled
                        isActive={value === index}
                        activeColor={String(theme.colors?.secondary)}
                      >
                        <input
                          name="radio"
                          type="radio"
                          id={`radio-${index}`}
                          defaultChecked={value === index}
                          value={`radio-${index}`}
                          style={{
                            opacity: 0,
                            width: 0,
                            height: 0,
                            display: 'none',
                          }}
                        />
                      </InputDotStyled>
                    </Box>
                  </Flex>
                </label>
              </ButtonBoxStyled>
            ))}
          </Flex>
        </form>
        <RatingDescriptionStyled>
          <Box
            sx={{
              width: '33.3%',
              overflowWrap: 'break-word',
            }}
          >
            {lowLabel}
          </Box>
          <Box
            sx={{
              width: '33.3%',
              textAlign: 'right',
              overflowWrap: 'break-word',
            }}
          >
            {highLabel}
          </Box>
        </RatingDescriptionStyled>
      </Container>
    </>
  );
};
