import { QuestionType } from '@common/models';

type NpsRatingQuestion = {
  id: string;
  header: string;
  isMandatory: boolean;
  type: QuestionType.NpsRating;
  lowLabel: string;
  highLabel: string;
  answer: number | null;
};

type StarRatingQuestion = {
  id: string;
  header: string;
  isMandatory: boolean;
  type: QuestionType.StarRating;
  labels: string[];
  description: string;
  answer: number[];
};

type WrittenFeedbackQuestion = {
  id: string;
  header: string;
  isMandatory: boolean;
  type: QuestionType.WrittenFeedback;
  placeholder: string;
  answer: string;
};

type MultipleChoiceQuestion = {
  id: string;
  header: string;
  isMandatory: boolean;
  type: QuestionType.MultipleChoice;
  description: string;
  minSelected: number;
  maxSelected: number;
  availableValues: string[];
  answer: string[];
};

export type Question =
  | NpsRatingQuestion
  | StarRatingQuestion
  | WrittenFeedbackQuestion
  | MultipleChoiceQuestion;

export function isNpsRatingQuestion(question: Question): question is NpsRatingQuestion {
  return question.type === QuestionType.NpsRating;
}
export function isStarRatingQuestion(question: Question): question is StarRatingQuestion {
  return question.type === QuestionType.StarRating;
}
export function isWrittenFeedbackQuestion(question: Question): question is WrittenFeedbackQuestion {
  return question.type === QuestionType.WrittenFeedback;
}
export function isMultipleChoiceQuestion(question: Question): question is MultipleChoiceQuestion {
  return question.type === QuestionType.MultipleChoice;
}

export type Survey = {
  id: string;
  title: string;

  welcomePageEnabled: boolean;
  welcomePageHeader: string;
  welcomePageSubHeader: string;
  welcomePageContent: string;

  endPageHeader: string;
  endPageSubHeader: string;
  endPageContent: string;
  endPagePrimaryButtonEnabled: boolean;
  endPagePrimaryButtonText: string;
  endPagePrimaryButtonUrl: string;
  endPageSecondaryButtonEnabled: boolean;
  endPageSecondaryButtonText: string;
  endPageSecondaryButtonUrl: string;

  backButtonText: string;
  nextButtonText: string;
  submitButtonText: string;

  questions: Question[];
};
