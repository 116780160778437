import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiClient } from '../../../store/apiClient';
// eslint-disable-next-line import/no-cycle
import { AppThunk, RootState } from '../../../store/store';
import { mapSurveyFromApi } from './mapSurveyFromApi';
import { mapToApiAnswer } from './mapToApiAnswer';
import { Survey } from './types';

export interface WizardState {
  isLoading: boolean;
  activeStep: number;
  participationGroupId?: string;
  survey?: Survey;
  submitState: 'notStartedYet' | 'inProgress' | 'success' | 'error' | 'captchaError';
  surveyStartTime?: Date;
  surveyOpenedTimeout?: ReturnType<typeof setTimeout>;
}

const initialState: WizardState = {
  isLoading: false,
  activeStep: 0,
  participationGroupId: undefined,
  survey: undefined,
  submitState: 'notStartedYet',
  surveyStartTime: undefined,
  surveyOpenedTimeout: undefined,
};

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setParticipationGroupId: (state, action: PayloadAction<string>) => {
      state.participationGroupId = action.payload;
    },
    setSurvey: (state, action: PayloadAction<Survey>) => {
      state.survey = action.payload;
    },
    setSubmitState: (state, action: PayloadAction<WizardState['submitState']>) => {
      state.submitState = action.payload;
    },
    setSurveyStartTime: (state, action: PayloadAction<Date>) => {
      state.surveyStartTime = action.payload;
    },
    setSurveyOpenedTimeout: (
      state,
      action: PayloadAction<ReturnType<typeof setTimeout> | undefined>,
    ) => {
      state.surveyOpenedTimeout = action.payload;
    },
    setAnswer: (
      state,
      action: PayloadAction<{
        index: number;
        value: string | number | number[] | string[];
      }>,
    ) => {
      const { index, value } = action.payload;

      if (state.survey) {
        state.survey.questions[index].answer = value;
      }
    },
  },
});

export const {
  setActiveStep,
  setIsLoading,
  setSurvey,
  setAnswer,
  setParticipationGroupId,
  setSubmitState,
  setSurveyStartTime,
  setSurveyOpenedTimeout,
} = wizardSlice.actions;

export const selectHasWelcomePage = (state: RootState): boolean => {
  return state.wizard.survey?.welcomePageEnabled || false;
};

export const selectQuestionCount = (state: RootState) => {
  return state.wizard.survey?.questions.length || 0;
};

export const selectIsQuestionStep = (state: RootState) => {
  const questionsCount = selectQuestionCount(state);
  const { activeStep } = state.wizard;

  return activeStep > 0 && activeStep <= questionsCount;
};

/** is last question step - ***without*** the thank you page! */
export const selectIsLastStep = (state: RootState) => {
  const stepCount = selectQuestionCount(state);
  const currentStep = state.wizard.activeStep;
  const isLastStep = currentStep === stepCount;

  return isLastStep;
};

export const selectIsFirstStep = (state: RootState) => {
  const hasWelcomeStep = selectHasWelcomePage(state);

  return hasWelcomeStep ? 0 : 1;
};

const submitSurvey =
  (token?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const store = getState();

      const { survey, participationGroupId } = store.wizard;

      if (!survey) {
        console.error('no survey to submit');
        return;
      }

      const surveyDuration = store.wizard.surveyStartTime
        ? new Date().getTime() - store.wizard.surveyStartTime.getTime()
        : 0;
      const answers = mapToApiAnswer(survey, surveyDuration, participationGroupId);

      await apiClient.submitAnswers(survey.id, answers, token);
      dispatch(setSubmitState('success'));
      dispatch(setActiveStep(store.wizard.activeStep + 1));
    } catch (err: any) {
      if (err?.message && err.message.includes('422')) {
        dispatch(setSubmitState('captchaError'));
      } else {
        dispatch(setSubmitState('error'));
      }
    }
  };

export const goToNextStep =
  (token?: string): AppThunk =>
  async (dispatch, getState) => {
    const store = getState();

    const { surveyOpenedTimeout } = store.wizard;
    const { participationGroupId } = store.wizard;
    if (surveyOpenedTimeout && participationGroupId) {
      clearTimeout(surveyOpenedTimeout);
      dispatch(setSurveyOpenedTimeout(undefined));
      apiClient.surveyOpened(participationGroupId);
    }

    const isLastStep = selectIsLastStep(store);
    if (isLastStep) {
      dispatch(submitSurvey(token));
      return;
    }

    const { activeStep } = store.wizard;
    dispatch(setActiveStep(activeStep + 1));
  };

export const goToPreviousStep = (): AppThunk => async (dispatch, getState) => {
  const store = getState();
  const currentStep = store.wizard.activeStep;

  const nextStep = currentStep - 1;

  if (nextStep < 0) {
    return;
  }

  dispatch(setActiveStep(nextStep));
};

export const fetchSurvey =
  (surveyId: string, previewKey?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const { participationGroupId } = getState().wizard;

      const survey = await apiClient.getSurvey(surveyId, previewKey);

      const mappedSurvey = mapSurveyFromApi(survey);

      dispatch(setSurvey(mappedSurvey));

      const hasWelcomeStep = selectHasWelcomePage(getState());

      if (hasWelcomeStep) {
        dispatch(setActiveStep(0));
      } else {
        dispatch(setActiveStep(1));
      }

      dispatch(setSurveyStartTime(new Date()));

      if (participationGroupId) {
        const timeout = setTimeout(() => {
          apiClient.surveyOpened(participationGroupId);
          dispatch(setSurveyOpenedTimeout(undefined));
        }, 2_000);
        dispatch(setSurveyOpenedTimeout(timeout));
      }
    } catch (err) {
      console.error('error while fetching survey', err);

      window.location.assign(`/error`);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const fetchSurveyByParticipationGroup =
  (participationGroupId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setParticipationGroupId(participationGroupId));

      const surveyId = await apiClient.getSurveyId(participationGroupId);

      dispatch(fetchSurvey(surveyId));
    } catch (err) {
      console.error('error while fetching surveyId by participation group id', err);

      window.location.assign(`/error`);
    }
  };
