import styled from '@emotion/styled';

type Props = {
  isActive: boolean;
  fillColor: string;
  borderColor?: string;
};

const SvgIcon = styled.svg`
  &:hover {
    path {
      opacity: 0.8;
    }
  }
`;

const SvgStarGroup = styled.g`
  fill: ${(props: Props) => (props.isActive ? props.fillColor : 'white')};
  stroke: ${(props: Props) => (props.isActive ? props.fillColor : props.borderColor)};
  transition: all 0.3s ease-out;
`;

export const Star = ({ isActive, fillColor, borderColor = '#000' }: Props) => (
  <SvgIcon width="38" height="38" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter
        x="-.5%"
        y="-4.2%"
        width="101.1%"
        height="108.3%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" transform="translate(-101 -36)" fill="none" fillRule="evenodd">
      <path d="M104 39h32v32h-32z" />
      <path d="M104 39h32v32h-32z" />
      <SvgStarGroup
        isActive={isActive}
        fillColor={fillColor}
        borderColor={borderColor}
        fillRule="nonzero"
      >
        <path d="M120 62.867l6.133 3.733c1.067.667 2.534-.267 2.267-1.6l-1.733-7.067 5.466-4.666c.934-.8.4-2.534-.8-2.534l-7.2-.666-2.8-6.667c-.533-1.2-2.266-1.2-2.666 0l-2.8 6.667-7.2.533c-1.334.133-1.867 1.733-.8 2.533l5.466 4.8-1.6 6.934c-.266 1.333 1.067 2.266 2.267 1.6l6-3.6z" />
      </SvgStarGroup>
    </g>
  </SvgIcon>
);
