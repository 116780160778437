import { PublicSurveyDto, QuestionDto, QuestionType } from '@common/models';

import { Question, Survey } from './types';

function mapQuestionFromApi(question: QuestionDto): Question | null {
  if (question.type === QuestionType.WrittenFeedback) {
    return {
      id: question.id,
      header: question.header,
      isMandatory: question.mandatory,
      type: QuestionType.WrittenFeedback,
      placeholder: question.displayData.placeholder,
      answer: '',
    };
  }
  if (question.type === QuestionType.NpsRating) {
    return {
      id: question.id,
      header: question.header,
      isMandatory: question.mandatory,
      type: QuestionType.NpsRating,
      lowLabel: question.displayData.lowLabel,
      highLabel: question.displayData.highLabel,
      answer: null,
    };
  }
  if (question.type === QuestionType.StarRating) {
    return {
      id: question.id,
      header: question.header,
      isMandatory: question.mandatory,
      type: QuestionType.StarRating,
      labels: question.displayData.labels,
      description: question.displayData.description,
      answer: Array(question.displayData.labels.length).fill(-1), // initial answers for each label
    };
  }
  if (question.type === QuestionType.MultipleChoice) {
    return {
      id: question.id,
      header: question.header,
      isMandatory: question.mandatory,
      type: QuestionType.MultipleChoice,
      description: question.displayData.description,
      availableValues: question.displayData.availableValues,
      maxSelected: question.displayData.maxSelected,
      minSelected: question.displayData.minSelected,
      answer: [],
    };
  }

  console.error('unsupported question type', { question });
  return null;
}

function isDefined<T>(value: T | undefined | null): value is T {
  return !!value;
}

export function mapSurveyFromApi(survey: PublicSurveyDto): Survey {
  const apiQuestions = survey.questions || [];
  const questions = apiQuestions.map(mapQuestionFromApi).filter(isDefined);

  return {
    id: survey.id,
    title: survey.title,
    questions,
    welcomePageEnabled: survey.welcomePageEnabled,
    welcomePageHeader: survey.welcomePageHeader,
    welcomePageSubHeader: survey.welcomePageSubHeader,
    welcomePageContent: survey.welcomePageContent,
    endPageHeader: survey.endPageHeader,
    endPageSubHeader: survey.endPageSubHeader,
    endPageContent: survey.endPageContent,
    endPagePrimaryButtonEnabled: survey.endPagePrimaryButtonEnabled,
    endPagePrimaryButtonText: survey.endPagePrimaryButtonText,
    endPagePrimaryButtonUrl: survey.endPagePrimaryButtonUrl,
    endPageSecondaryButtonEnabled: survey.endPageSecondaryButtonEnabled,
    endPageSecondaryButtonText: survey.endPageSecondaryButtonText,
    endPageSecondaryButtonUrl: survey.endPageSecondaryButtonUrl,
    backButtonText: survey.backButtonText,
    nextButtonText: survey.nextButtonText,
    submitButtonText: survey.submitButtonText,
  };
}

// TODO unit tests
