import { StarRating } from '../../components/questions/StarRating';
import { useFrameResizerBroadcast } from '../../hooks/useFrameResizerBroadcast';
import { QuestionWrapper } from './QuestionWrapper';

const WrappedStarRatingQuestion = QuestionWrapper(StarRating, {
  header: 'Is this a Written Feedback Question?',
  onChange: () => {},
  labels: ['Amount of questions', 'Easy to understand', 'Design'],
  description: 'Rating base: 1 - very bad … 5 - very good',
  stars: [2, 4, 1],
  surveyId: 'test',
});

export const StarRatingPage = () => {
  useFrameResizerBroadcast();

  return <WrappedStarRatingQuestion />;
};
