import { NpsRating } from '../../components/questions/NpsRating';
import { useFrameResizerBroadcast } from '../../hooks/useFrameResizerBroadcast';
import { QuestionWrapper } from './QuestionWrapper';

const WrappedNpsRatingQuestion = QuestionWrapper(NpsRating, {
  header: 'Is this a Written Feedback Question?',
  onChange: () => {},
  lowLabel: 'Happy',
  highLabel: 'Unhappy',
  value: 10,
  surveyId: 'test',
});

export const NpsRatingPage = () => {
  useFrameResizerBroadcast();

  return <WrappedNpsRatingQuestion />;
};
