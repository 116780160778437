import { WrittenFeedback } from '../../components/questions/WrittenFeedback';
import { useFrameResizerBroadcast } from '../../hooks/useFrameResizerBroadcast';
import { QuestionWrapper } from './QuestionWrapper';

const WrappedWrittenFeedbackQuestion = QuestionWrapper(WrittenFeedback, {
  header: 'Is this a Written Feedback Question?',
  onChange: () => {},
  value: '',
  surveyId: 'test',
});

export const WrittenFeedbackPage = () => {
  useFrameResizerBroadcast();

  return <WrappedWrittenFeedbackQuestion />;
};
