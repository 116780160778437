import { motion } from 'framer-motion';
import * as React from 'react';
import { ReactNode } from 'react';

import { useAppSelector } from '../../store/store';

const variants = {
  enter: (direction: number) => ({
    x: direction < 0 ? -100 : 100,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    y: direction < 0 ? 100 : -100,
    opacity: 0,
  }),
};

interface AnimatedStepProps {
  children: ReactNode;
}

export const AnimatedStep = ({ children }: AnimatedStepProps) => {
  const activeStep = useAppSelector((store) => store.wizard.activeStep);
  const [lastStep, setLastStep] = React.useState(activeStep);

  React.useEffect(() => {
    setLastStep(activeStep);
  }, [activeStep]);

  return (
    <motion.div
      key={activeStep}
      custom={activeStep - lastStep}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};
