import styled from '@emotion/styled';
import { Box } from 'theme-ui';

import theme from '../../utils/theme';

export const WizardContent = styled(Box)`
  overflow: hidden;
  min-height: calc(100% - 52px);

  @media screen and (min-width: 48em) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } ;
`;

export const StepContainer = styled(Box)`
  padding: 2px 20px 20px 20px;
  margin: 18px 12px;
  border-radius: 9px;
  background-color: ${theme.colors.white};
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.12), 0 9px 46px 8px rgba(0, 0, 0, 0.1),
    0 11px 15px -7px rgba(0, 0, 0, 0.16);

  @media screen and (min-width: 48em) {
    width: 720px;
    margin: 30px auto 40px auto;
  } ;
`;
