import { AnalyticsClient } from './client/analyticsClient';
import { IAnalyticsClient } from './client/analyticsClient.interface';
import { AnalyticsMockClient } from './client/analyticsMockClient';
import { isLocalhost } from './helpers';

// eslint-disable-next-line import/no-mutable-exports
let analyticsClient: IAnalyticsClient;

// for local development do not use analytics
const isAnalyticsEnabled = !isLocalhost();

if (isAnalyticsEnabled) {
  analyticsClient = new AnalyticsClient();
} else {
  analyticsClient = new AnalyticsMockClient();
}

export { analyticsClient };
