/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { Box, Container, Heading } from 'theme-ui';

import { analyticsClient } from '../../analytics';
import { fullWidthOnChar } from '../../utils/helpers';

type Props = {
  header: string;
  subHeader: string;
  description: string;
  surveyId: string;
};

export const Welcome = ({ header, subHeader, description, surveyId }: Props) => {
  useEffect(() => {
    analyticsClient.surveyWelcomePageOpened(surveyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Heading
        as="h3"
        mt={[6, 6, 7]}
        mb={[6]}
        sx={{
          fontSize: 5,
          textAlign: 'center',
        }}
      >
        {header}
      </Heading>

      <Container>
        <Box
          sx={{
            textAlign: 'center',
            color: 'gray',
          }}
        >
          {subHeader}
        </Box>
        <Box my={[3, 3, 7]}>
          <Box
            sx={{
              textAlign: 'center',
              color: 'gray',
              maxWidth: fullWidthOnChar(120, description),
              margin: '0 auto',
            }}
          >
            {description}
          </Box>
        </Box>
      </Container>
    </>
  );
};
