import React from 'react';

import { MutipleChoice } from '../../components/questions/MutipleChoice';
import { NpsRating } from '../../components/questions/NpsRating';
import { StarRating } from '../../components/questions/StarRating';
import { WrittenFeedback } from '../../components/questions/WrittenFeedback';
import {
  isMultipleChoiceQuestion,
  isNpsRatingQuestion,
  isStarRatingQuestion,
  isWrittenFeedbackQuestion,
  Question,
} from './store/types';

type Props = {
  question: Question;
  index: number;
  onChange: (value: string | number | number[] | string[]) => void;
  stepCount: number;
  currentStep: number;
  surveyId: string;
};

export const QuestionComponent = ({
  index,
  question,
  onChange,
  currentStep,
  stepCount,
  surveyId,
}: Props) => {
  const header = `${index + 1}. ${question.header}`;

  if (isNpsRatingQuestion(question)) {
    return (
      <NpsRating
        onChange={onChange}
        header={header}
        value={question.answer}
        lowLabel={question.lowLabel}
        highLabel={question.highLabel}
        currentStep={currentStep}
        stepCount={stepCount}
        surveyId={surveyId}
      />
    );
  }

  if (isStarRatingQuestion(question)) {
    return (
      <StarRating
        onChange={onChange}
        header={header}
        labels={question.labels}
        description={question.description}
        stars={question.answer}
        currentStep={currentStep}
        stepCount={stepCount}
        surveyId={surveyId}
      />
    );
  }

  if (isWrittenFeedbackQuestion(question)) {
    return (
      <WrittenFeedback
        onChange={onChange}
        header={header}
        value={question.answer}
        currentStep={currentStep}
        stepCount={stepCount}
        surveyId={surveyId}
      />
    );
  }

  if (isMultipleChoiceQuestion(question)) {
    return (
      <MutipleChoice
        onChange={onChange}
        header={header}
        description={question.description}
        minSelected={question.minSelected}
        maxSelected={question.maxSelected}
        availableValues={question.availableValues}
        value={question.answer}
        currentStep={currentStep}
        stepCount={stepCount}
        surveyId={surveyId}
      />
    );
  }

  return null;
};
