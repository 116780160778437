import { getBrand, getEnvironment } from '@common/models';

import { waitForPageReady } from '../helpers';
import { TrackingObject } from '../models/trackingObject.model';
import { IAnalyticsClient } from './analyticsClient.interface';

declare const window: any;

export class AnalyticsClient implements IAnalyticsClient {
  public async errorPageOpened(): Promise<void> {
    return this.performAnalyticsAction({
      event: 'PageLoaded',
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
          issueDate: '24.04.2024',
          pageName: 'ust:error-page',
        },
        category: {
          pagePurpose: 'information',
          pageType: 'error page',
          primaryCategory: 'survey',
        },
      },
    });
  }

  public async privacyPageOpened(): Promise<void> {
    return this.performAnalyticsAction({
      event: 'PageLoaded',
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
          issueDate: '24.04.2024',
          pageName: 'ust:privacy-page',
        },
        category: {
          pagePurpose: 'information',
          pageType: 'privacy page',
          primaryCategory: 'survey',
        },
      },
    });
  }

  public async cookiesRejectedPageOpened(): Promise<void> {
    return this.performAnalyticsAction({
      event: 'PageLoaded',
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
          issueDate: '24.04.2024',
          pageName: 'ust:cookies-rejected-page',
        },
        category: {
          pagePurpose: 'information',
          pageType: 'cookies page',
          primaryCategory: 'survey',
        },
      },
    });
  }

  public async surveyWelcomePageOpened(surveyId: string): Promise<void> {
    return this.performAnalyticsAction({
      event: 'PageLoaded',
      transaction: {
        transactionID: surveyId,
      },
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
          issueDate: '24.04.2024',
          pageName: 'ust:welcome-step',
        },
        category: {
          pagePurpose: 'information',
          pageType: 'survey page',
          primaryCategory: 'survey',
        },
      },
    });
  }

  public async surveyThankYouPageOpened(surveyId: string): Promise<void> {
    return this.performAnalyticsAction({
      event: 'PageLoaded',
      transaction: {
        transactionID: surveyId,
      },
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
          issueDate: '24.04.2024',
          pageName: 'ust:thank-you-step',
        },
        category: {
          pagePurpose: 'information',
          pageType: 'survey page',
          primaryCategory: 'survey',
        },
      },
    });
  }

  public async surveyQuestionPageOpened(surveyId: string, questionIndex?: number): Promise<void> {
    return this.performAnalyticsAction({
      event: 'PageLoaded',
      transaction: {
        transactionID: surveyId,
      },
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
          issueDate: '24.04.2024',
          pageName: `ust:question-${questionIndex}`,
        },
        category: {
          pagePurpose: 'information',
          pageType: 'survey page',
          primaryCategory: 'survey',
        },
      },
    });
  }

  public async surveyPrimaryButtonClicked(surveyId: string): Promise<void> {
    return this.performAnalyticsAction({
      event: 'GeneralClick',
      eventdetails: {
        clickedElement: 'PrimaryButton',
        clickType: 'o',
      },
      transaction: {
        transactionID: surveyId,
      },
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
        },
      },
    });
  }

  public async surveySecondaryButtonClicked(surveyId: string): Promise<void> {
    return this.performAnalyticsAction({
      event: 'GeneralClick',
      eventdetails: {
        clickedElement: 'SecondaryButton',
        clickType: 'o',
      },
      transaction: {
        transactionID: surveyId,
      },
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
        },
      },
    });
  }

  public async surveySubmitButtonClicked(participationGroupId: string): Promise<void> {
    return this.performAnalyticsAction({
      event: 'GeneralClick',
      eventdetails: {
        clickedElement: 'SubmitButton',
        clickType: 'o',
      },
      transaction: {
        transactionID: participationGroupId,
      },
      page: {
        attributes: {
          displayedBrand: getBrand(),
        },
        pageInfo: {
          sysEnv: getEnvironment(),
        },
      },
    });
  }

  private async performAnalyticsAction(trackingObject: TrackingObject): Promise<void> {
    await waitForPageReady();
    window.appEventData = window.appEventData || [];
    window.appEventData.push(trackingObject);
  }
}
