import { ThankYou } from '../../components/steps/ThankYou';
import { useFrameResizerBroadcast } from '../../hooks/useFrameResizerBroadcast';
import { QuestionWrapper } from './QuestionWrapper';

const WrappedThankYouPageQuestion = QuestionWrapper(ThankYou, {
  header: 'Thank you for your feedback',
  subHeader: 'Your opinion is important to us.',
  description:
    'Do you want to help even more? We are searching for people that can be our testers in the future.',
  primaryButtonEnabled: false,
  primaryButtonText: '',
  primaryButtonUrl: '',
  secondaryButtonEnabled: false,
  secondaryButtonText: '',
  secondaryButtonUrl: '',
  surveyId: 'test',
});

export const ThankYouPage = () => {
  useFrameResizerBroadcast();

  return <WrappedThankYouPageQuestion />;
};
