// tslint:disable no-console
import { waitForPageReady } from '../helpers';
import { IAnalyticsClient } from './analyticsClient.interface';

export class AnalyticsMockClient implements IAnalyticsClient {
  public errorPageOpened(): Promise<void> {
    return this.performMockedAnalyticsAction('errorPageOpened');
  }

  public privacyPageOpened(): Promise<void> {
    return this.performMockedAnalyticsAction('privacyPageOpened');
  }

  public cookiesRejectedPageOpened(): Promise<void> {
    return this.performMockedAnalyticsAction('cookiesRejectedPageOpened');
  }

  public surveyWelcomePageOpened(surveyId: string): Promise<void> {
    return this.performMockedAnalyticsAction('surveyWelcomePageOpened', { surveyId });
  }

  public surveyThankYouPageOpened(surveyId: string): Promise<void> {
    return this.performMockedAnalyticsAction('surveyThankYouPageOpened', { surveyId });
  }

  public surveyQuestionPageOpened(surveyId: string, questionIndex?: number): Promise<void> {
    return this.performMockedAnalyticsAction('surveyQuestionPageOpened', {
      surveyId,
      questionIndex,
    });
  }

  public surveyPrimaryButtonClicked(surveyId: string): Promise<void> {
    return this.performMockedAnalyticsAction('surveyPrimaryButtonClicked', { surveyId });
  }

  public surveySecondaryButtonClicked(surveyId: string): Promise<void> {
    return this.performMockedAnalyticsAction('surveySecondaryButtonClicked', { surveyId });
  }

  public surveySubmitButtonClicked(participationGroupId: string): Promise<void> {
    return this.performMockedAnalyticsAction('surveySubmitButtonClicked', { participationGroupId });
  }

  private async performMockedAnalyticsAction(methodName: string, params?: unknown): Promise<void> {
    await waitForPageReady();
    const methodParams = params && JSON.stringify(params);
    const invokedMethod = `${methodName}(${methodParams ?? ''})`;
    console.log(`[AnalyticsMockClient]: Analytics.${invokedMethod}`);
  }
}
