/** @jsxImportSource theme-ui */
import styled from '@emotion/styled';
import { Box, Flex } from 'theme-ui';

import { hexToRGB } from '../../utils/helpers';
import theme from '../../utils/theme';

type StyledProps = {
  isActive: boolean;
  activeColor: string;
  elementIndex?: number;
};

export const NumberStyled = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: ${(props: StyledProps) => (props.isActive ? props.activeColor : 'transparent')};
  color: ${(props: StyledProps) => (props.isActive ? '#fff' : '#000')};
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease-out;

  @media screen and (min-width: 64em) {
    font-size: 19px;
    background-color: ${(props: StyledProps) => (props.isActive ? props.activeColor : '#f2f2f2')};
  } ;
`;

export const ButtonBoxStyled = styled(Box)`
  width: 44px;
  height: 44px;
  margin: 2px;
  border-radius: 50%;
  border-width: 1px;
  border-color: ${(props: StyledProps) => (props.isActive ? props.activeColor : '#e8e8e8')};
  border-style: solid;
  overflow: hidden;
  background-color: ${(props: StyledProps) =>
    props.isActive
      ? props.activeColor
      : hexToRGB('#f2f2f2', (((props.elementIndex! + 1) * (100 / 11)) / 100).toFixed(2))};
  color: ${(props: StyledProps) => (props.isActive ? '#fff' : '#000')};
  cursor: pointer;
  transition: all 0.3s ease-out;

  @media screen and (min-width: 64em) {
    width: 50px;
    height: auto;
    border-radius: 9px;
    margin: 0;
  } ;
`;

export const RatingDescriptionStyled = styled(Flex)`
  width: 300px;
  margin: 8px auto;
  justify-content: space-between;
  font-size: 11px;
  color: ${theme.colors.grayBorder};

  @media screen and (min-width: 64em) {
    width: auto;
  } ;
`;

export const InputDotStyled = styled(Box)`
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #fff;
  content: '';
  border-radius: 50%;
  border-color: #000;
  border-width: 2px;
  border-style: solid;

  &:after {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${(props: StyledProps) => props.activeColor};
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;
    opacity: ${(props: StyledProps) => (props.isActive ? 1 : 0)};
    transition: opacity 0.2s ease-in;
  }
`;
