import { useMemo } from 'react';

import { useAppSelector } from '../../../store/store';
import {
  isMultipleChoiceQuestion,
  isNpsRatingQuestion,
  isStarRatingQuestion,
  isWrittenFeedbackQuestion,
  Question,
} from './types';

function isQuestionValid(question?: Question): boolean {
  if (!question) {
    return false;
  }

  if (!question.isMandatory) {
    return true;
  }

  if (isNpsRatingQuestion(question)) {
    return question.answer !== null;
  }

  if (isStarRatingQuestion(question)) {
    return !question.answer.includes(-1);
  }

  if (isWrittenFeedbackQuestion(question)) {
    return question.answer.length > 0;
  }

  if (isMultipleChoiceQuestion(question)) {
    return question.answer.length >= question.minSelected;
  }

  return false;
}

export const useIsStepValid = (): boolean => {
  const activeStep = useAppSelector((store) => store.wizard.activeStep);
  const survey = useAppSelector((store) => store.wizard.survey);

  const isWelcomeStep = activeStep === 0;

  const question = survey?.questions[activeStep - 1];

  const isValid = useMemo(() => {
    if (isWelcomeStep) {
      return true;
    }

    return isQuestionValid(question);
  }, [question, isWelcomeStep]);

  return isValid;
};
