import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const COOKIES_REJECTED_PAGE = '/cookiesRejected';
const SESSION_STORAGE_KEY = 'cookie-modal-rejected';
const SESSION_STORAGE_REDIRECT_KEY = 'cookies-rejected-redirect';

export const CookieModal = () => {
  const history = useHistory();
  const location = useLocation();

  const isOnCookiesRejectedPage = location.pathname === COOKIES_REJECTED_PAGE;

  function optanonChanged() {
    // this function is used by external CookieConsentTool handler - cannot use react or hook specific data

    if (!(window as any).Optanon?.OnConsentChanged) {
      // optanon not loaded yet
      return;
    }

    const isPageInFrame = window.parent !== window;
    if (isPageInFrame) {
      // page is in an iframe
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const isOnCookiesRejectedPage = window.location.pathname === COOKIES_REJECTED_PAGE;

    const isAdobeNotAccepted = window.OptanonActiveGroups?.indexOf('C0002') === -1;
    const isAdobeAccepted = !isAdobeNotAccepted;

    const isAlreadyRejected = sessionStorage.getItem(SESSION_STORAGE_KEY) === 'true';
    const isAlertBoxClosed = (window as any).Optanon?.IsAlertBoxClosed();
    if (isOnCookiesRejectedPage && !isAlreadyRejected && isAlertBoxClosed && isAdobeNotAccepted) {
      // force show modal when already rejected on another page
      (window as any).Optanon.ToggleInfoDisplay();
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
      return;
    }

    if (isOnCookiesRejectedPage) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
    }

    if (!isOnCookiesRejectedPage && isAdobeAccepted) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
    }

    if (isOnCookiesRejectedPage && isAdobeAccepted) {
      const redirectUrl = sessionStorage.getItem(SESSION_STORAGE_REDIRECT_KEY);
      if (redirectUrl) {
        sessionStorage.removeItem(SESSION_STORAGE_REDIRECT_KEY);
        history.push(redirectUrl);
      }
      return;
    }

    if (isAdobeNotAccepted && !isOnCookiesRejectedPage) {
      sessionStorage.setItem(SESSION_STORAGE_REDIRECT_KEY, window.location.pathname);
      history.push(COOKIES_REJECTED_PAGE);
    }
  }

  useEffect(() => {
    optanonChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnCookiesRejectedPage]);

  useOnOptanonLoaded(() => {
    (window as any).Optanon.OnConsentChanged(optanonChanged);
    optanonChanged();
  });

  return null;
};

const useOnOptanonLoaded = (callback: () => void) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if ((window as any).Optanon?.OnConsentChanged) {
        try {
          clearInterval(interval);
          callback();
        } catch (e) {
          console.error(e);
        }
      }
    }, 200);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
