import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Flex } from 'theme-ui';

import { analyticsClient } from '../../analytics';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useIsStepValid } from './store/useIsStepValid';
import {
  goToNextStep,
  goToPreviousStep,
  selectIsFirstStep,
  selectIsLastStep,
  setSubmitState,
} from './store/wizardSlice';

export const ActionButtons = () => {
  const dispatch = useAppDispatch();

  const participationGroupId = useAppSelector((store) => store.wizard.participationGroupId);

  const activeStep = useAppSelector((store) => store.wizard.activeStep);
  const submitState = useAppSelector((store) => store.wizard.submitState);
  const isFirstStep = useAppSelector(selectIsFirstStep);
  const isLastStep = useAppSelector(selectIsLastStep);
  const isValid = useIsStepValid();

  const backButtonText = useAppSelector((store) => store.wizard.survey?.backButtonText);
  const nextButtonText = useAppSelector((store) => store.wizard.survey?.nextButtonText);
  const submitButtonText = useAppSelector((store) => store.wizard.survey?.submitButtonText);

  const isSending = submitState === 'inProgress';
  const isSubmitted = submitState === 'success';
  const canGoBack = !isSending;
  const canGoNext = !isSending && isValid;

  const shouldHideBackButton = activeStep === isFirstStep;

  const { executeRecaptcha } = useGoogleReCaptcha();

  if (isSubmitted) {
    return null;
  }

  const handleGoNext = async () => {
    if (isLastStep) {
      dispatch(setSubmitState('inProgress'));

      if (participationGroupId) {
        analyticsClient.surveySubmitButtonClicked(participationGroupId);
      }

      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('submit');

      dispatch(goToNextStep(token));
    } else {
      dispatch(goToNextStep());
    }
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        '@media screen and (min-width: 48em)': {
          justifyContent: 'space-between',
          flexDirection: 'row',
        },
      }}
      mt={[6]}
      mb={[5]}
    >
      <Button
        onClick={() => canGoBack && dispatch(goToPreviousStep())}
        disabled={!canGoBack}
        variant="ergoOneOutline"
        style={{ visibility: shouldHideBackButton ? 'hidden' : 'visible' }}
      >
        {backButtonText || ''}
      </Button>
      <Button
        onClick={() => canGoNext && handleGoNext()}
        disabled={!canGoNext}
        variant="ergoOnePrimary"
      >
        {isLastStep ? submitButtonText || '' : nextButtonText || ''}
      </Button>
    </Flex>
  );
};
