import { makeTheme } from '@theme-ui/css/utils';

const generalButton = {
  width: '270px',
  margin: '6px 0',
  borderRadius: '25px',
  fontSize: '14px',
  transition: 'all .2s ease-in',
  cursor: 'pointer',

  '@media screen and (min-width: 48em)': {
    width: 'auto',
    minWidth: '150px',
  },
  ':disabled': {
    opacity: 0.6,
    cursor: 'not-allowed',

    '&:hover': {
      opacity: 0.6,
    },
  },
};

const ergoColors = {
  primary: '#821739;',
  secondary: '#8E0038',
};

export const base = makeTheme({
  // (1em = 16px)
  // in px          360     768    1024    1280     1600
  breakpoints: ['22.5em', '48em', '64em', '80em', '100em'],
  space: [0, 4, 8, 16, 18, 24, 32, 64, 128, 256, 512],
  fonts: {
    body: 'FSMeWeb-Regular, sans-serif',
    heading: 'FSMeWeb-Bold, sans-serif',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    thin: 300,
    normal: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  config: {
    useCustomProperties: false,
    useColorSchemeMediaQuery: false,
    useLocalStorage: false,
  },
  colors: {
    background: '#fff',
    black: '#000',
    white: '#fff',
    gray: '#a1a1a1',
    grayLight: '#f7f7f7',
    grayBorder: '#CACACA',
    grayBg: '#f8f9fa',
    ...ergoColors,
    modes: {
      ergo: {
        ...ergoColors,
      },
      dkv: {
        primary: '#07483f;',
        secondary: '#88a327',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'normal',
    },
    ergoOnespinner: {
      color: 'primary',
      size: '32px',
    },
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      lineHeight: 'heading',
    },
  },
  buttons: {
    ergoOnePrimary: {
      ...generalButton,
      color: 'white',
      bg: 'primary',
      fontFamily: 'heading',
      '&:hover': {
        opacity: 0.8,
      },
    },
    ergoOneOutline: {
      ...generalButton,
      color: 'primary',
      bg: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary',
    },
  },
});

export default base;

export type ThemeType = typeof base;
