export const QuestionWrapper =
  <T extends Record<string, unknown>>(Component: (props: T) => JSX.Element, defaultProps: T) =>
  () => {
    let props = defaultProps;

    try {
      const query = new URLSearchParams(window.location.search);
      const dataBase64 = query.get('data');
      const dataStrEncoded = atob(dataBase64 || '');

      const dataStr = decodeURI(dataStrEncoded);
      const data = JSON.parse(dataStr);

      props = { ...props, ...data };
    } catch (err) {
      console.warn('Could not parse data query', err);
    }

    return <Component {...props} />;
  };
