import { useInterval } from './useInterval';

export const useFrameResizerBroadcast = () => {
  useInterval(() => {
    if (window.parent) {
      const eventData = { type: 'resize', height: document.documentElement.offsetHeight };

      window.parent.postMessage(eventData, '*');
    }
  }, 100);

  return null;
};
