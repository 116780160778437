/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { Textarea } from 'theme-ui';

import { analyticsClient } from '../../analytics';
import { Container } from '../Container';
import { StepHeadline } from './StepHeadline';

type Props = {
  header: string;
  value: string | undefined;
  onChange: (value: string) => void;
  stepCount?: number;
  currentStep?: number;
  surveyId: string;
};

export const WrittenFeedback = ({
  header,
  onChange,
  value,
  currentStep,
  stepCount,
  surveyId,
}: Props) => {
  useEffect(() => {
    analyticsClient.surveyQuestionPageOpened(surveyId, currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StepHeadline title={header} currentStep={currentStep} stepCount={stepCount} />

      <Container>
        <Textarea
          placeholder=""
          value={value}
          rows={9}
          sx={{
            borderColor: 'grayBorder',
            borderRadius: '4px',
            boxShadow: 'inset 0 2px 5px 0 rgba(0,0,0,0.15)',
            resize: 'none',
            fontSize: 'inherit',
            fontFamily: 'inherit',
          }}
          onChange={(e) => onChange(e.target.value)}
        />
      </Container>
    </>
  );
};
