import { getDomainConfig } from '@common/models';
import { useEffect } from 'react';
import { useColorMode } from 'theme-ui';

export const useCustomColorMode = () => {
  const { host } = window.location;

  const [, setColorMode] = useColorMode();

  useEffect(() => {
    const domain = getDomainConfig();

    if (domain.dkv === host) setColorMode('dkv');
  }, [setColorMode, host]);
};
