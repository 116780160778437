/** @jsxImportSource theme-ui */
// styling reference workaround
import styled from '@emotion/styled/macro';
import { Box } from 'theme-ui';

import Checkbox from '../../assets/checkbox.svg';

export const Option = styled(Box)`
  margin: 16px 0;
  border-radius: 9px;
  border: 1px solid #ededed;
`;

export const OptionText = styled(Box)`
  width: 100%;
  padding: 8px 16px;
  background-color: #ededed;
  border: 2px solid #fff;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  font-size: 19px;
  transition: all 0.2s ease-out;
  font-family: 'FSMeWeb-Regular';
  overflow-wrap: break-word;
  cursor: pointer;
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  height: 100%;

  input[type='radio'],
  input[type='checkbox'] {
    /* hide native element */
    appearance: none;
    background-color: #fff;
    margin: 0;
    /* style */
    position: relative;
    width: 48px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    transition: all 0.2s ease-out;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 14px;
      left: 14px;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 2px solid #000;
      content: '';
    }

    &:after {
      position: absolute;
      top: 19px;
      left: 19px;
      width: 10px;
      height: 10px;
      content: '';
      transition: transform 0.2s ease-out;
      transform: scale(0);
    }

    &:checked {
      &:after {
        transform: scale(1);
      }
      background-color: ${({ theme }) => theme.colors.secondary};

      & + ${OptionText} {
        background-color: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;

      & + ${OptionText} {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }

  input[type='radio'] {
    &:before {
      border-radius: 50%;
    }
    &:after {
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  input[type='checkbox'] {
    &:after {
      top: 21px;
      left: 19px;
      width: 11px;
      height: 11px;
      background: url(${Checkbox});
      background-size: 10px;
      background-repeat: no-repeat;
    }
  }
`;
