import { Box, BoxOwnProps, BoxProps } from 'theme-ui';

export const Container = ({ children, ...props }: BoxOwnProps & BoxProps) => (
  <Box
    sx={{
      maxWidth: '720px',
    }}
    {...props}
  >
    {children}
  </Box>
);
