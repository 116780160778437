import { Box, Flex, Heading } from 'theme-ui';

type Props = {
  title: string;
  stepCount?: number;
  currentStep?: number;
};

export const StepHeadline = ({ title, currentStep, stepCount }: Props) => {
  const shouldDisplayCounter = typeof stepCount === 'number' && typeof currentStep === 'number';

  return (
    <Flex
      sx={{
        '@media screen and (min-width: 48em)': {
          justifyContent: 'space-between',
        },
      }}
    >
      <Heading
        as="h3"
        mt={[4]}
        mb={[5]}
        sx={{
          fontSize: 4,
          overflowWrap: 'break-word',
        }}
      >
        {title}
      </Heading>
      {shouldDisplayCounter && (
        <Box
          sx={{
            display: 'none',
            '@media screen and (min-width: 48em)': {
              display: 'inline-block',
              textAlign: 'right',
              paddingTop: '18px',
              paddingRight: '10px',
              paddingLeft: '10px',
            },
          }}
        >
          {currentStep}/{stepCount}
        </Box>
      )}
    </Flex>
  );
};
