import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading } from 'theme-ui';

import { analyticsClient } from '../analytics';
import { ReactComponent as FeedbackIcon } from '../assets/feedback_icon.svg';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { AdjustPageHeight } from '../utils/globalStyles';

export const CookiesRejectedPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    analyticsClient.cookiesRejectedPageOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkPrivacyPolicy() {
    (window as any).Optanon.ToggleInfoDisplay();
  }

  return (
    <>
      <AdjustPageHeight />
      <Box>
        <Header />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: '16px',
            overflow: 'hidden',
            minHeight: 'calc(100% - 86px)',
            '@media screen and (min-width: 48em)': {
              maxWidth: '720px',
              margin: '32px auto',
            },
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
              marginBottom: '32px',
            }}
          >
            <FeedbackIcon
              style={{
                marginTop: '8px',
                marginBottom: '48px',
              }}
            />
            <Heading
              style={{
                marginBottom: '16px',
              }}
            >
              {t('cookiesRejected.header')}
            </Heading>
            <span
              style={{
                marginBottom: '40px',
              }}
            >
              {t('cookiesRejected.content')}
            </span>
            <Button variant="ergoOnePrimary" onClick={() => checkPrivacyPolicy()}>
              {t('cookiesRejected.primaryButton')}
            </Button>
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};
