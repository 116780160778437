import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { Flex, Spinner } from 'theme-ui';

import { ThankYou } from '../../components/steps/ThankYou';
import { Welcome } from '../../components/steps/Welcome';
import { useConfig } from '../../hooks/useConfig';
import { useOnMount } from '../../hooks/useOnMount';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { AdjustPageHeight } from '../../utils/globalStyles';
import { ErrorPage } from '../ErrorPage';
import { QuestionComponent } from './Question';
import {
  fetchSurvey,
  fetchSurveyByParticipationGroup,
  selectQuestionCount,
  setAnswer,
} from './store/wizardSlice';
import { Wizard } from './Wizard';

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export const WizardPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const query = useQuery();
  const previewKey = query.get('previewKey');
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((store) => store.wizard.isLoading);
  const survey = useAppSelector((store) => store.wizard.survey);
  const submitState = useAppSelector((store) => store.wizard.submitState);
  const activeStep = useAppSelector((store) => store.wizard.activeStep);

  const questionsCount = useAppSelector(selectQuestionCount);

  const envConfig = useConfig();

  const isAppLoading = isLoading || !envConfig;

  const loadSurvey = () => {
    if (previewKey) {
      dispatch(fetchSurvey(id, previewKey));
    } else {
      dispatch(fetchSurveyByParticipationGroup(id));
    }
  };

  useOnMount(loadSurvey);

  if (!id) {
    return <Redirect to="/not-found" />;
  }

  if (isAppLoading || !survey) {
    return (
      <>
        <AdjustPageHeight />
        <Flex
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner variant="styles.ergoOnespinner" />
        </Flex>
      </>
    );
  }

  if (submitState === 'error') {
    return (
      <ErrorPage
        header={t('errorPage.header.unknown')}
        description={t('errorPage.description.unknown')}
      />
    );
  }

  if (submitState === 'captchaError') {
    return (
      <ErrorPage
        header={t('errorPage.header.captcha')}
        description={t('errorPage.description.captcha')}
      />
    );
  }
  return (
    <GoogleReCaptchaProvider reCaptchaKey={envConfig.reCaptchaSiteKey}>
      <AdjustPageHeight />
      <Wizard>
        {/* Welcome step will not be displayed when disabled */}
        <Welcome
          header={survey.welcomePageHeader}
          subHeader={survey.welcomePageSubHeader}
          description={survey.welcomePageContent}
          surveyId={id}
        />

        {survey.questions.map((question, index) => (
          <QuestionComponent
            key={question.id}
            index={index}
            question={question}
            onChange={(value) => dispatch(setAnswer({ index, value }))}
            currentStep={activeStep}
            stepCount={questionsCount}
            surveyId={id}
          />
        ))}

        <ThankYou
          header={survey.endPageHeader}
          subHeader={survey.endPageSubHeader}
          description={survey.endPageContent}
          primaryButtonEnabled={survey.endPagePrimaryButtonEnabled}
          primaryButtonText={survey.endPagePrimaryButtonText}
          primaryButtonUrl={survey.endPagePrimaryButtonUrl}
          secondaryButtonEnabled={survey.endPageSecondaryButtonEnabled}
          secondaryButtonText={survey.endPageSecondaryButtonText}
          secondaryButtonUrl={survey.endPageSecondaryButtonUrl}
          surveyId={id}
        />
      </Wizard>
    </GoogleReCaptchaProvider>
  );
};
