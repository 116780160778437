/** @jsxImportSource theme-ui */
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Box, Text, useThemeUI } from 'theme-ui';

import { analyticsClient } from '../../analytics';
import { useCustomColorMode } from '../../hooks/useCustomColorMode';
import { rangeWithKey } from '../../utils/helpers';
import { onEnter } from '../../utils/onEnter';
import { Container } from '../Container';
import { Star } from '../StarIcon';
import { StepHeadline } from './StepHeadline';

type Props = {
  header: string;
  onChange: (value: number[]) => void;
  labels: string[];
  description: string;
  stars: number[];
  stepCount?: number;
  currentStep?: number;
  surveyId: string;
};

const StarButtonStyled = styled(Box)`
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease-out;
`;

export const StarRating = ({
  header,
  onChange,
  labels,
  stars,
  currentStep,
  stepCount,
  description,
  surveyId,
}: Props) => {
  const handleClick = (starValue: number, labelIndex: number) => {
    const nextValue = [...stars];

    nextValue[labelIndex] = starValue;
    onChange(nextValue);
  };

  const { theme } = useThemeUI();

  useEffect(() => {
    analyticsClient.surveyQuestionPageOpened(surveyId, currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCustomColorMode();

  return (
    <>
      <StepHeadline title={header} currentStep={currentStep} stepCount={stepCount} />

      <Container>
        {labels.map((label, labelIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={label + labelIndex}>
            <Box mt={[5]}>
              <Text
                sx={{
                  fontFamily: 'heading',
                }}
              >
                {label}
              </Text>
            </Box>

            <Box my={[2]}>
              {rangeWithKey(5).map((key, index) => (
                <StarButtonStyled
                  onClick={() => handleClick(index, labelIndex)}
                  key={key}
                  tabIndex={0}
                  onKeyPress={onEnter(() => handleClick(index, labelIndex))}
                >
                  <Star
                    isActive={stars[labelIndex] >= index}
                    fillColor={String(theme.colors?.primary)}
                  />
                </StarButtonStyled>
              ))}
            </Box>
          </Box>
        ))}

        <Box
          sx={{
            fontSize: '0',
            overflowWrap: 'break-word',
          }}
          color="gray"
          my={3}
        >
          {description}
        </Box>
      </Container>
    </>
  );
};
