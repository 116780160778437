export async function waitForPageReady(): Promise<void> {
  return new Promise<void>((resolve) => {
    if (document.readyState === 'complete') {
      resolve();
    } else {
      window.addEventListener('load', (event) => {
        resolve();
      });
    }
  });
}

export function isLocalhost(): boolean {
  return (
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    // eslint-disable-next-line security/detect-unsafe-regex
    !!window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})){3}$/)
  );
}
