import { KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading } from 'theme-ui';

import { analyticsClient } from '../analytics';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

type Lang = 'en-GB' | 'de-DE';

export const PrivacyPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    analyticsClient.privacyPageOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (lang: Lang) => {
    i18n.changeLanguage(lang);
  };

  const handleKeyPess = (e: KeyboardEvent<HTMLElement>, lang: Lang) => {
    if (e.key === 'Enter') {
      changeLanguage(lang);
    }
  };

  const isGermanLang = (lang: string) => {
    return /^de/.test(lang);
  };

  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: '16px',
          overflow: 'hidden',
          minHeight: 'calc(100% - 52px)',
          '@media screen and (min-width: 48em)': {
            maxWidth: '720px',
            margin: '32px auto',
          },
        }}
      >
        <Box>
          <Flex
            sx={{
              width: '100%',
              alignSelf: 'end',
            }}
          >
            <Heading
              sx={{
                flex: '1 1 100%',
              }}
            >
              {t('privacy.header')}
            </Heading>
            <Box
              sx={{
                width: '60px',
              }}
            >
              {isGermanLang(i18n.language) ? (
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  role="link"
                  onKeyDown={(e) => handleKeyPess(e, 'en-GB')}
                  tabIndex={0}
                  onClick={() => changeLanguage('en-GB')}
                >
                  EN
                </span>
              ) : (
                <span
                  style={{
                    cursor: 'pointer',
                  }}
                  role="link"
                  onKeyDown={(e) => handleKeyPess(e, 'de-DE')}
                  tabIndex={0}
                  onClick={() => changeLanguage('de-DE')}
                >
                  DE
                </span>
              )}
            </Box>
          </Flex>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.terminologySection.header')}
            </Box>
            <Box my={2}>{t('privacy.terminologySection.paragraphs.1')}</Box>
            <Box my={2}>{t('privacy.terminologySection.paragraphs.2')}</Box>
            <Box my={2}>{t('privacy.terminologySection.paragraphs.3')}</Box>
            <Box my={2}>{t('privacy.terminologySection.paragraphs.4')}</Box>
            <Box my={2}>{t('privacy.terminologySection.paragraphs.5')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.introductionSection.header')}
            </Box>
            <Box my={2}>{t('privacy.introductionSection.paragraphs.1')}</Box>
            <Box my={2}>{t('privacy.introductionSection.paragraphs.2')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.controllerSection.header')}
            </Box>
            <Box my={2}>{t('privacy.controllerSection.paragraphs.1')}</Box>
            <Box my={2}>{t('privacy.controllerSection.paragraphs.2')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.dataProcessingSection.header')}
            </Box>
            <Box my={2}>{t('privacy.dataProcessingSection.paragraphs.1')}</Box>
            <Box my={2}>{t('privacy.dataProcessingSection.paragraphs.2')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.durationOfDataProcessingSection.header')}
            </Box>
            <Box my={2}>{t('privacy.durationOfDataProcessingSection.paragraphs.1')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.purposeAndLegalBasisOfDataProcessingSection.header')}
            </Box>
            <Box my={2}>
              {t('privacy.purposeAndLegalBasisOfDataProcessingSection.paragraphs.1')}
            </Box>
            <Box my={2}>
              {t('privacy.purposeAndLegalBasisOfDataProcessingSection.paragraphs.2')}
            </Box>
            <Box my={2}>
              {t('privacy.purposeAndLegalBasisOfDataProcessingSection.paragraphs.3')}
            </Box>
            <Box my={2}>
              {t('privacy.purposeAndLegalBasisOfDataProcessingSection.paragraphs.4')}
            </Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.entitiesInvolved.header')}
            </Box>
            <Box my={2}>{t('privacy.entitiesInvolved.paragraphs.1')}</Box>
            <Box my={2}>{t('privacy.entitiesInvolved.paragraphs.2')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.rightsSection.header')}
            </Box>
            <Box my={2}>{t('privacy.rightsSection.items.1.header')}</Box>
            <Box ml={4}>{t('privacy.rightsSection.items.1.description')}</Box>

            <Box my={2}>{t('privacy.rightsSection.items.2.header')}</Box>
            <Box ml={4}>{t('privacy.rightsSection.items.2.description')}</Box>

            <Box my={2}>{t('privacy.rightsSection.items.3.header')}</Box>
            <Box ml={4}>{t('privacy.rightsSection.items.3.description')}</Box>

            <Box my={2}>{t('privacy.rightsSection.items.4.header')}</Box>
            <Box ml={4} my={2}>
              {t('privacy.rightsSection.items.4.description.paragraph1')}
            </Box>
            <Box ml={4}>{t('privacy.rightsSection.items.4.description.paragraph2')}</Box>

            <Box my={2}>{t('privacy.rightsSection.items.5.header')}</Box>
            <Box ml={4}>{t('privacy.rightsSection.items.5.description')}</Box>

            <Box my={2}>{t('privacy.rightsSection.items.6.header')}</Box>
            <Box ml={4}>{t('privacy.rightsSection.items.6.description')}</Box>

            <Box my={2}>{t('privacy.rightsSection.items.7.header')}</Box>
            <Box ml={4}>{t('privacy.rightsSection.items.7.description')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.amendmentsSection.header')}
            </Box>
            <Box my={2}>{t('privacy.amendmentsSection.paragraphs.1')}</Box>
          </Box>

          <Box
            sx={{
              margin: '12px auto',
            }}
          >
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.contactSesion.header')}
            </Box>
            <Box>{t('privacy.contactSesion.description')}</Box>
            <Box style={{ display: 'flex', marginLeft: '2em' }}>
              <Box style={{ minWidth: '200px' }}>
                {t('privacy.contactSesion.emailAddress.header')}
              </Box>
              <Box>{t('privacy.contactSesion.emailAddress.value')}</Box>
            </Box>
            <Box style={{ display: 'flex', marginLeft: '2em' }}>
              <Box style={{ minWidth: '200px' }}>
                {t('privacy.contactSesion.postalAddress.header')}
              </Box>
              <Box>
                <Box>{t('privacy.contactSesion.postalAddress.value.line1')}</Box>
                <Box>{t('privacy.contactSesion.postalAddress.value.line2')}</Box>
                <Box>{t('privacy.contactSesion.postalAddress.value.line3')}</Box>
                <Box>{t('privacy.contactSesion.postalAddress.value.line4')}</Box>
              </Box>
            </Box>
            <Box
              sx={{
                margin: '32px 0 16px 0',
                fontSize: '18px',
                fontFamily: 'FSMeWeb-Bold, sans-serif',
              }}
            >
              {t('privacy.summary.header')}
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: t('privacy.summary.content') }} />
          </Box>
        </Box>

        <Footer />
      </Box>
    </Box>
  );
};
