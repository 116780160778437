import { SurveyAnswerDto } from '@common/models';

import {
  isMultipleChoiceQuestion,
  isNpsRatingQuestion,
  isStarRatingQuestion,
  isWrittenFeedbackQuestion,
  Question,
  Survey,
} from './types';

function getQuestionAnswerData(question: Question) {
  if (isNpsRatingQuestion(question) && typeof question.answer === 'number') {
    // do not send non-mandatory if missing
    return {
      rate: question.answer,
    };
  }

  if (isStarRatingQuestion(question)) {
    // non-mandatory are sent as 0
    return {
      stars: question.answer.map((star) => star + 1), // backend stars 1-5 frontend stars 0-4
    };
  }

  if (isWrittenFeedbackQuestion(question) && question.answer) {
    // do not send non-mandatory if missing
    return {
      text: question.answer,
    };
  }

  if (isMultipleChoiceQuestion(question) && question.answer) {
    // do not send non-mandatory if missing
    return {
      selectedValues: question.answer,
    };
  }
  return undefined;
}

const mapQuestionToAnswer = (question: Question) => {
  return {
    question: question.id,
    type: question.type,
    data: getQuestionAnswerData(question) as any, // TODO any
  };
};

const getTechnicals = (): SurveyAnswerDto['technicals'] => {
  return {
    userAgent: navigator.userAgent,
    browserResolution: `${window.innerWidth}x${window.innerHeight}`,
    resolution: `${window.screen.width}x${window.screen.height}`,
  };
};

export const mapToApiAnswer = (
  survey: Survey,
  duration: number,
  participantGroupId?: string,
): SurveyAnswerDto => {
  return {
    participantGroupHash: participantGroupId || '',
    technicals: getTechnicals(),
    answers: survey.questions.map(mapQuestionToAnswer).filter((q) => !!q.data),
    duration,
  };
};
