/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { Box, Button, Container, Heading } from 'theme-ui';

import { analyticsClient } from '../../analytics';
import { fullWidthOnChar } from '../../utils/helpers';

type Props = {
  header: string;
  subHeader: string;
  description: string;
  primaryButtonEnabled: boolean;
  primaryButtonText: string;
  primaryButtonUrl: string;
  secondaryButtonEnabled: boolean;
  secondaryButtonText: string;
  secondaryButtonUrl: string;
  surveyId: string;
};

export const ThankYou = ({
  header,
  subHeader,
  description,
  primaryButtonEnabled,
  primaryButtonText,
  primaryButtonUrl,
  secondaryButtonEnabled,
  secondaryButtonText,
  secondaryButtonUrl,
  surveyId,
}: Props) => {
  useEffect(() => {
    analyticsClient.surveyThankYouPageOpened(surveyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrimaryButtonClicked = () => {
    analyticsClient.surveyPrimaryButtonClicked(surveyId);
  };

  const onSecondaryButtonClicked = () => {
    analyticsClient.surveySecondaryButtonClicked(surveyId);
  };

  return (
    <>
      <Heading
        as="h3"
        mt={[6, 6, 7]}
        mb={[6]}
        sx={{
          fontSize: 5,
          textAlign: 'center',
        }}
      >
        {header}
      </Heading>

      <Container>
        <Box
          sx={{
            textAlign: 'center',
            color: 'gray',
          }}
        >
          {subHeader}
        </Box>
        <Box my={[3, 3, 7]}>
          <Box
            sx={{
              textAlign: 'center',
              color: 'gray',
              maxWidth: fullWidthOnChar(120, description),
              margin: '0 auto',
            }}
          >
            {description}
          </Box>
        </Box>
      </Container>

      {(primaryButtonEnabled || secondaryButtonEnabled) && (
        <Container mt="6">
          {primaryButtonEnabled && (
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <a href={primaryButtonUrl || '#'} onClick={onPrimaryButtonClicked}>
                <Button tabIndex={-1} variant="ergoOnePrimary">
                  {primaryButtonText}
                </Button>
              </a>
            </Box>
          )}
          {secondaryButtonEnabled && (
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <a href={secondaryButtonUrl || '#'} onClick={onSecondaryButtonClicked}>
                <Button tabIndex={-1} variant="ergoOneOutline">
                  {secondaryButtonText}
                </Button>
              </a>
            </Box>
          )}
        </Container>
      )}
    </>
  );
};
