import { css, Global } from '@emotion/react';

export const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'FSMeWeb-Light';
        src: url('/fonts/FSMeWeb-Light.woff') format('woff');
        font-weight: 300;
      }

      @font-face {
        font-family: 'FSMeWeb-Regular';
        src: url('/fonts/FSMeWeb-Regular.woff') format('woff');
        font-weight: 500;
      }

      @font-face {
        font-family: 'FSMeWeb-Bold';
        src: url('/fonts/FSMeWeb-Bold.woff') format('woff');
        font-weight: 700;
      }

      /* Modal styles */
      .ReactModal__Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
      }

      .ReactModal__Content {
        position: absolute;
        width: calc(100% - 32px);
        height: calc(100vh - 32px);
        top: 16px;
        left: 16px;
        background: #fff;
        border-radius: 9px;
        outline: none;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.16), 0px 9px 46px 8px rgba(0, 0, 0, 0.1),
          0px 24px 38px 3px rgba(0, 0, 0, 0.12);

        @media (min-width: 768px) {
          max-width: 814px;
          max-height: 770px;
          top: 50%;
          left: 50%;
          right: auto;
          bottom: auto;
          transform: translate(-50%, -50%);
        }
      }
    `}
  />
);

export const AdjustPageHeight = () => (
  <Global
    styles={css`
      html,
      body,
      #root,
      #root > div {
        height: 100%;
      }
    `}
  />
);
