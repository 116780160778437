import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading } from 'theme-ui';

import { analyticsClient } from '../analytics';
import { ReactComponent as ErrorIcon } from '../assets/error_icon.svg';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { AdjustPageHeight } from '../utils/globalStyles';

type Props = {
  header?: string;
  description?: string;
};

export const ErrorPage = ({ header, description }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    analyticsClient.errorPageOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AdjustPageHeight />
      <Box>
        <Header />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
            minHeight: 'calc(100% - 52px)',
            '@media screen and (min-width: 48em)': {
              maxWidth: '720px',
              margin: '0 auto',
            },
          }}
        >
          <Flex
            sx={{
              flex: '3 0 auto',
              textAlign: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                marginBottom: '40px',
              }}
            >
              <ErrorIcon />
            </Box>

            <Heading>{header || t('errorPage.header.notFound')}</Heading>

            <Box
              sx={{
                marginTop: '16px',
                color: 'gray',
                maxWidth: '300px',
                width: '100%',
                margin: '16px auto',
              }}
            >
              {description || t('errorPage.description.notFound')}
            </Box>
          </Flex>
          <Footer />
        </Box>
      </Box>
    </>
  );
};
