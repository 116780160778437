import { Welcome } from '../../components/steps/Welcome';
import { useFrameResizerBroadcast } from '../../hooks/useFrameResizerBroadcast';
import { QuestionWrapper } from './QuestionWrapper';

const WrappedWelcomePageQuestion = QuestionWrapper(Welcome, {
  header: 'Welcome',
  subHeader: 'Your opinion is important to us.',
  description:
    "With your help we want to improve our tool. It shouldn't take more than few minutes",
  surveyId: 'test',
});

export const WelcomePage = () => {
  useFrameResizerBroadcast();

  return <WrappedWelcomePageQuestion />;
};
