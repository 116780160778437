import styled from '@emotion/styled';
import { Box, Flex, useColorMode } from 'theme-ui';

import { ReactComponent as DkvLogo } from '../assets/DKV_logo.svg';
import { ReactComponent as ErgoLogo } from '../assets/ERGO_logo.svg';
import { selectIsQuestionStep, selectQuestionCount } from '../pages/Wizard/store/wizardSlice';
import { useAppSelector } from '../store/store';
import theme from '../utils/theme';

const HeaderStyled = styled(Flex)`
  height: 52px;
  padding: 0 20px 0 12px;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  align-items: center;
  box-shadow: 0 2px 8px 0 #1c13133f;
  z-index: 1;

  @media screen and (min-width: 48em) {
    justify-content: center;
  } ;
`;

export const Header = () => {
  const activeStep = useAppSelector((store) => store.wizard.activeStep);
  const questionsCount = useAppSelector(selectQuestionCount);
  const isQuestionStep = useAppSelector(selectIsQuestionStep);

  const [colorMode] = useColorMode();

  return (
    <HeaderStyled>
      {colorMode === 'dkv' ? <DkvLogo width="72" /> : <ErgoLogo width="72" />}

      {isQuestionStep && (
        <Box
          sx={{
            '@media screen and (min-width: 48em)': {
              display: 'none',
            },
          }}
        >
          {activeStep}/{questionsCount}
        </Box>
      )}
    </HeaderStyled>
  );
};
