import { AnimatePresence } from 'framer-motion';
import React, { ReactNode, useMemo } from 'react';
import { Box } from 'theme-ui';

import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { useAppSelector } from '../../store/store';
import { ActionButtons } from './ActionButtons';
import { AnimatedStep } from './AnimatedStep';
import { StepContainer, WizardContent } from './Wizard.styles';

type Props = {
  children: ReactNode;
};

export const Wizard = ({ children }: Props) => {
  const activeStep = useAppSelector((store) => store.wizard.activeStep);

  const ActiveStepComponent = useMemo(() => {
    const reactChildren = React.Children.toArray(children);

    return reactChildren[activeStep];
  }, [children, activeStep]);

  return (
    <Box>
      <Header />
      <WizardContent>
        <Box
          sx={{
            flex: '3 0 auto',
            '@media screen and (min-width: 48em)': {
              paddingTop: '40px',
            },
          }}
        >
          <AnimatePresence>
            <AnimatedStep>
              <StepContainer>
                {ActiveStepComponent}
                <ActionButtons />
              </StepContainer>
            </AnimatedStep>
          </AnimatePresence>
        </Box>

        <Footer />
      </WizardContent>
    </Box>
  );
};
