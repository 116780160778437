import { EndUserAppConfigDto, PublicSurveyDto, SurveyAnswerDto } from '@common/models';
import axios from 'axios';

const api = axios.create();

export const apiClient = {
  getSurveyId: async (participationGroupId: string): Promise<string> => {
    const url = `/api/public/surveyIdByPgHash/${participationGroupId}`;
    const resp = await api.get<string>(url);

    return resp.data;
  },

  getSurvey: async (id: string, previewKey?: string): Promise<PublicSurveyDto> => {
    const urlParams = new window.URLSearchParams();

    if (previewKey) {
      urlParams.append('previewKey', previewKey);
    }

    const resp = await api.get<PublicSurveyDto>(`/api/public/survey/${id}?${urlParams}`);

    return resp.data;
  },

  surveyOpened: async (id: string): Promise<void> => {
    await api.post(`/api/public/${id}/opened`);
  },

  submitAnswers: async (surveyId: string, survey: SurveyAnswerDto, token?: string) => {
    await api.post(`/api/public/${surveyId}/submit?token=${token}`, survey);
  },

  getConfig: async (): Promise<EndUserAppConfigDto> => {
    const resp = await api.get<EndUserAppConfigDto>('/api/public/config');

    return resp.data;
  },
};
