import { MutipleChoice } from '../../components/questions/MutipleChoice';
import { useFrameResizerBroadcast } from '../../hooks/useFrameResizerBroadcast';
import { QuestionWrapper } from './QuestionWrapper';

const WrappedMultipleChoiceQuestion = QuestionWrapper(MutipleChoice, {
  header: 'Is this a multiple choice Question?',
  onChange: () => {},
  description: `Please select at lest 1 option, and up to 3 options.`,
  minSelected: 1,
  maxSelected: 1,
  availableValues: ['Option 1', ' Option 2'],
  value: ['Option 1'],
  surveyId: 'test',
});

export const MultipleChoicePage = () => {
  useFrameResizerBroadcast();

  return <WrappedMultipleChoiceQuestion />;
};
