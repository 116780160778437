import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { CookieModal } from './components/CookieModal';
import { useCustomColorMode } from './hooks/useCustomColorMode';
import { CookiesRejectedPage } from './pages/CookiesRejectedPage';
import { ErrorPage } from './pages/ErrorPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { MultipleChoicePage } from './pages/Steps/MultipleChoice';
import { NpsRatingPage } from './pages/Steps/NpsRating';
import { StarRatingPage } from './pages/Steps/StarRating';
import { ThankYouPage } from './pages/Steps/TankYouPage';
import { WelcomePage } from './pages/Steps/WelcomePage';
import { WrittenFeedbackPage } from './pages/Steps/WrittenFeedback';
import { WizardPage } from './pages/Wizard/WizardPage';

export const App = () => {
  useCustomColorMode();
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/questions/WrittenFeedback">
          <WrittenFeedbackPage />
        </Route>
        <Route path="/questions/NpsRating">
          <NpsRatingPage />
        </Route>
        <Route path="/questions/StarRating">
          <StarRatingPage />
        </Route>
        <Route path="/questions/MultipleChoice">
          <MultipleChoicePage />
        </Route>
        <Route path="/questions/ThankYou">
          <ThankYouPage />
        </Route>
        <Route path="/questions/Welcome">
          <WelcomePage />
        </Route>
        <Route path="/survey/:id">
          <WizardPage />
        </Route>
        <Route path="/privacy">
          <PrivacyPage />
        </Route>
        <Route path="/cookiesRejected">
          <CookiesRejectedPage />
        </Route>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
      <CookieModal />
    </BrowserRouter>
  );
};
