export const rangeWithKey = (range: number) =>
  Array(range)
    .fill(null)
    .map((_, index) => index);

export const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const fullWidthOnChar = (
  characters: number,
  content: string,
  defaultWidth: string = '280px',
): string => {
  if (content.length > characters) {
    return 'auto';
  }
  return defaultWidth;
};
