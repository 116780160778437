import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './locales/de/translation.json';
import en from './locales/en/translation.json';

const languageDetector = new LanguageDetector(null, {
  order: ['navigator'],
});

const resources = {
  en,
  de,
};

i18n.use(languageDetector).use(initReactI18next).init({
  resources,
  defaultNS: 'common',
  fallbackLng: 'en',
});
