import { EndUserAppConfigDto } from '@common/models';
import { useEffect, useState } from 'react';

import { apiClient } from '../store/apiClient';

const cache: { value: EndUserAppConfigDto | null } = {
  value: null,
};

export const useConfig = () => {
  const [config, setConfig] = useState<EndUserAppConfigDto | null>(null);

  useEffect(() => {
    if (cache.value) {
      setConfig(cache.value);
      return;
    }

    apiClient
      .getConfig()
      // eslint-disable-next-line promise/always-return
      .then((appConfig) => {
        cache.value = appConfig;
        setConfig(appConfig);
      })
      .catch((err) => {
        throw err; // app can't work without configuration
      });
  }, []);

  return config;
};
