/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource theme-ui */

import { useEffect } from 'react';
import { Box, Flex } from 'theme-ui';

import { analyticsClient } from '../../analytics';
import { useCustomColorMode } from '../../hooks/useCustomColorMode';
import { Container } from '../Container';
import { Label, Option, OptionText } from './MultipleChoice.styles';
import { StepHeadline } from './StepHeadline';

type Props = {
  header: string;
  onChange: (value: string[]) => void;
  description: string;
  minSelected: number;
  maxSelected: number;
  availableValues: string[];
  value: string[];
  stepCount?: number;
  currentStep?: number;
  surveyId: string;
};

export const MutipleChoice = ({
  header,
  onChange,
  description,
  minSelected,
  maxSelected,
  availableValues,
  value,
  currentStep,
  stepCount,
  surveyId,
}: Props) => {
  useCustomColorMode();

  useEffect(() => {
    analyticsClient.surveyQuestionPageOpened(surveyId, currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRadio = maxSelected === 1;

  const handleClick = (option: string) => {
    if (isRadio) {
      onChange([option]);
    } else if (value.includes(option)) {
      onChange(value.filter((e) => e !== option));
    } else {
      onChange(Array.from(new Set([...value, option])));
    }
  };

  const unselectRadio = (option: string) => {
    if (isRadio && value.includes(option)) {
      onChange(value.filter((e) => e !== option));
    }
  };

  const checkIsDisabled = (option: string) => {
    if (value.length >= maxSelected) {
      return !value.includes(option);
    }
  };

  return (
    <>
      <StepHeadline title={header} currentStep={currentStep} stepCount={stepCount} />

      <Container>
        <Box
          sx={{
            fontSize: '2',
            overflowWrap: 'break-word',
          }}
          color="gray"
          my="-20px"
        >
          {description}
        </Box>
        <Box mt={6}>
          <form>
            {availableValues.map((option, i) => {
              return (
                <Option key={option}>
                  <Label htmlFor={option + i}>
                    <Flex>
                      <input
                        id={option + i}
                        type={isRadio ? 'radio' : 'checkbox'}
                        value={option}
                        onClick={() => unselectRadio(option)}
                        onChange={() => handleClick(option)}
                        name={header}
                        checked={value.includes(option)}
                        disabled={isRadio ? false : checkIsDisabled(option)}
                      />
                      <OptionText>{option}</OptionText>
                    </Flex>
                  </Label>
                </Option>
              );
            })}
          </form>
        </Box>
      </Container>
    </>
  );
};
